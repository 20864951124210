<template>
  <div>
    <div class="container-fluid">
      <div class="tab-content" style="min-height: 750px; padding: 20px;">
        <anatomy :selected_anatomy_region="{}" :selected_anatomy_parent="{}"></anatomy>
        <div class="card" v-if="false">
          <!-- false false false -->
          <div class="card-header">
            <strong>Anatomi bölgeleri</strong>
            <div class="pull-right">
              <button type="button" class="btn btn-success" @click="operation()">İşlemi uygula</button>
              <button type="button" class="btn btn-success" @click="save()">{{ $t('wdm16.4061') }}</button>
            </div>
          </div>
          <div class="card-block">
            <div class="form-group row">
              <div class="col-12">
                <input type="text" class="form-control" name="search" placeholder="Lütfen arama yaptığınız kelimeyi buraya yazınız." v-model="search_text">
              </div>
            </div>
            <div class="form-group row" v-if="option_data.anatomy">
              <div class="col-12">
                <span style="color: red;">
                  {{ search_data.length > 0 ? search_data.length : option_data.anatomy.list.length }} adet bölge listelenmektedir.
                </span>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th style="width: 40%;">Anatomik bölge label</th>
                      <!-- <th>başlık</th> -->
                      <th>Türkçe</th>
                      <th>İngilizce</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(ana, ana_ind) in option_data.anatomy.list" v-if="control_search_data(ana_ind)">
                      <td> <b>{{ ana_ind + 1 }} -)</b> {{ ana.value }} </td>
                      <!-- <td><input  type="text" class="form-control" name="caption" v-model="ana.label"></td> -->
                      <td>
                        <input type="text" class="form-control" name="tr" v-model="ana.translation.tr">
                      </td>
                      <td>
                        <input type="text" class="form-control" name="en" v-model="ana.translation.en">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
// import {default as router} from '@/router';
import {
  default as Modal } from '@/components/widgets/Modal';
import {
  default as Anatomy } from '@/components/widgets/Anatomy';
import OptionService from '@/services/option';

export default {
  name: 'option_anatomy',
  computed: {
    ...mapGetters({
      lang: 'lang'
    })
  },
  created: function () {},
  mounted: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    let data = { option_list: ['male_anatomy'], 'page_type': 'main' };
    OptionService.get_list(data)
      .then(resp => {
        if (resp.data.status === 'success') {
          this.option_data = resp.data.result;
          this.search_data = [];
        } else {
          console.log('FUNC, mounted, ERROR::: ', resp.data.message);
        }
      });
  },
  data () {
    return {
      male: { 'caption': 'male', 'translation': { 'tr': 'erkek', 'en': 'male' }, 'value': 'male', 'system': { 'caption': 'sistem', 'translation': { 'tr': 'sistem', 'en': 'system' }, 'value': 'system', 'list': [], 'sub': {} } },
      user: {},
      search_data: [],
      option_data: {},
      search_text: '',
      anatomy_data: { system: '', sub_1: '', sub_2: '', sub_3: '', sub_4: '', sub_5: '' }
    };
  },
  methods: {
    operation: function () {
      for (let i in this.option_data.anatomy.list) {
        let split = this.option_data.anatomy.list[i].value.split('_');
        if (split.length > 4) { console.log('4ten fazla', i); }
        let data = '';
        for (let k = 1; k < split.length; k++) {
          if (k === 1) { data = this.male.system; }
          if (k === 2) { data = this.male.system.sub[split[k - 1]]; }
          if (k === 3) { data = this.male.system.sub[split[k - 2]].sub[split[k - 1]]; }
          if (k !== split.length - 1) {
            if (data.sub[split[k]] === undefined) {
              data.sub[split[k]] = { 'caption': split[k], 'translation': { 'tr': split[k], 'en': split[k] }, 'label': split[k], 'list': [], 'sub': {} };
            }
          }
          let det = false;
          for (let li in data.list) {
            if (data.list[li].value === split[k]) {
              det = true;
              break;
            }
          }
          if (!det) {
            data.list.push({ 'caption': split[k], 'translation': { 'tr': split[k], 'en': split[k] }, 'label': split[k] });
          }
        }
      }
      alert('İşlem tamamlandı.');
    },
    control_search_data (ana_ind) {
      if (this.search_data.length > 0) {
        if (this.search_data.indexOf(ana_ind.toString()) !== -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    save: function () {
      let data = { 'data': JSON.parse(JSON.stringify(this.option_data)), 'page_type': this.page_type };
      OptionService.save(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === 'success') {
            this.option_data = Object.assign({}, this.option_data, resp.data.result);
            msg = this.$t('wdm16.12661');
          } else {
            msg = 'Error: ' + resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': this.$t('wdm16.12656') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    },
    search: function () {
      for (let i in this.option_data.anatomy.list) {
        if (this.option_data.anatomy.list[i].label.indexOf(this.search_text) !== -1 || this.option_data.anatomy.list[i].value.indexOf(this.search_text) !== -1 || this.option_data.anatomy.list[i].translation.tr.indexOf(this.search_text) !== -1) {
          this.search_data.push(i);
        }
      }
    }
  },
  components: {
    Modal,
    vSelect,
    Anatomy
  },
  watch: {
    'search_text': function () {
      this.search_data = [];
      if (this.search_text) {
        this.search();
      } else {
        this.search_data = [];
      }
    }
  }
};

</script>

